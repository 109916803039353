/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from "@/util/api.ts";
import router from "@/router";
import Vue from "vue";
import { getTipTotal } from "@/types/dto/eateries/eateryDtoFunctions";
import { getCartItem, findCartItemIndex, getCurrentCartItem } from "../helpers";
import {CheckoutType} from "@/types/enums/checkoutType";

export default {
  async updateLastNavigationPage({ commit }, lastPage) {
    commit("UPDATE_LAST_NAVIGATION_PAGE", lastPage);
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async sendRequestMessage({ commit }, msgObject) {
    const res = await api.sendRequestMessage(msgObject);
    return res;
  },
  async setSnackbarText({ commit }, snackbarText) {
    commit("UPDATE_SNACKBAR_TEXT", snackbarText);
    commit("UPDATE_SHOW_SNACKBAR", true);
    setTimeout(() => {
      commit("UPDATE_SHOW_SNACKBAR", false);
    }, 2000);
    // commit("UPDATE_SHOW_SNACKBAR", false);
  },
  async showSnackbar({ commit }, show) {
    commit("UPDATE_SHOW_SNACKBAR", show);
  },
  async verifyUserAccount(userGuid) {
    const res = await api.verifyUserAccount(userGuid);
    return res;
  },
  async newVerifyUserAccount(userGuid) {
    const res = await api.sendNewVerifyUserAccount(userGuid);
    return res;
  },
  async checkVerifyUserAccount(userGuid) {
    const res = await api.checkVerifyUserAccount(userGuid);
    return res;
  },
  async updateOrderAsReserved({ commit }, paymentId) {
    const data = await api.updateOrderAsReserved(paymentId);
    commit("SET_PAYMENT_ID", data.paymentId);
    return data;
  },
  updateShowShoppingCart({ commit, dispatch }, show) {
    commit("UPDATE_SHOW_SHOPPING_CART", show);
    dispatch("updateErrorStatus");
  },
  updateMessageToKitchen({ commit }, msgToKitchen) {
    commit("UPDATE_MSG_TO_KITCHEN", msgToKitchen);
  },
  setLoading({ commit }, status) {
    commit("UPDATE_LOADER", status);
  },
  async getSubmittedOrderCookie() {
    const orderCookie = await Vue.$cookies.get("submittedOrder");
    return orderCookie ?? null;
  },
  async getGDPRCookie({ commit }) {
    const GDPRCookie = await Vue.$cookies.get("CookieConsent");
    if (!GDPRCookie) return null;
    commit("SET_GDPRCOOKIE", GDPRCookie);
    return GDPRCookie;
  },
  async setGDPRCookie({ commit }, cookieInfo) {
    await Vue.$cookies.set(
      "CookieConsent",
      JSON.stringify(cookieInfo),
      "12M",
      null,
      null,
      true,
      "Strict"
    );
    commit("SET_GDPRCOOKIE", cookieInfo);
  },
  async getMenus({ commit }, cname) {
    let menus = this.state.menus ?? [];
    const eateryInfo = this.state.eateryInfo;
    const checkValidMenuCache =
      menus.filter((x) => x.eateryId !== eateryInfo?.eateryId).length === 0;
    const checkIfMenuExists =
      eateryInfo && menus.length > 0 && checkValidMenuCache;
    if (checkIfMenuExists) return menus;

    menus = await api.getMenus(cname, this.state.token);
    commit("UPDATE_MENUS", menus);
    return menus;
  },
  async getMenu({ commit }, id) {
    const menu = await api.getMenu(id, this.state.token);
    if (menu.groupedMenuItems) {
      menu.groupedMenuItems.forEach((elm) => {
        elm.menuItems.forEach((mi) => {
          mi.foodProperties.forEach((fp) => {
            if (!fp.multiSelect) {
              const hasSelectedFoodProperties = fp.foodPropertyOptions.some(
                (fpo) => fpo.selected === true
              );
              if (!hasSelectedFoodProperties) {
                const firstElm = fp.foodPropertyOptions[0];
                if (firstElm) {
                  firstElm.selected = true;
                }
              }
            }
          });
          if (mi.menuItemVariants) {
            mi.menuItemVariants.forEach((miv) => {
              miv.selected = false;
            });
          }
        });
      });
    }
    commit("UPDATE_MENU", menu);
    if (menu != null) {
      commit("SET_LAST_MENU_ID", menu.id);
    }
    return menu;
  },
  async setMenu({ commit }, menu) {
    commit("UPDATE_MENU", menu);
    return menu;
  },
  clearMenu({ commit }) {
    commit("CLEAR_MENU");
  },
  async setActiveMenuItem(context, activeMenuItem) {
    await context.commit("SET_ACTIVE_MENUITEM", activeMenuItem);
    return activeMenuItem;
  },
  async addCartItem({ commit, dispatch }, menuItem) {
    const cartItem = getCartItem(menuItem);
    cartItem.CustomerMessage = menuItem.customerMessage;
    const cartIndex = findCartItemIndex(this.state, cartItem);
    const currentCartItem = getCurrentCartItem(this.state, cartItem);
    if (
      cartIndex >= 0 &&
      !currentCartItem?.CustomerMessage &&
      !menuItem.customerMessage
    ) {
      commit("INCREMENT_CART_ITEM", currentCartItem);
    } else {
      commit("ADD_TO_CART", cartItem);
    }
    dispatch("updateErrorStatus");

    return cartItem;
  },
  async incrementCartItem({ commit, dispatch }, cartItem) {
    commit("INCREMENT_CART_ITEM", cartItem);
    dispatch("updateErrorStatus");
    return cartItem;
  },
  async decrementCartItem({ commit, dispatch }, cartItem) {
    commit("DECREMENT_CART_ITEM", cartItem);
    dispatch("updateErrorStatus");
    return cartItem;
  },
  async removeCartItem({ commit, dispatch }, cartItem) {
    commit("REMOVE_FROM_CART", cartItem);
    dispatch("updateErrorStatus");
    return true;
  },
  async updateCartItemWithMessage({ commit, dispatch }, payload) {
    commit("UPDATE_CART_ITEM_MESSAGE", payload);
    return true;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getUserOrders({ commit }, pageQuery) {
    return await api.getOrders(
      this.state.token,
      pageQuery.pageNumber,
      pageQuery.pageSize,
      pageQuery.sort
    );
  },
  async saveOrder({ commit, getters }, order) {
    const currentCart = getters.cart;
    let orderSum = 0;
    currentCart.forEach(function (cartItem) {
      if (cartItem.nrOfItems > 1) {
        orderSum += cartItem.totalPrice * cartItem.nrOfItems;
      } else {
        orderSum += cartItem.totalPrice;
      }
    });
    let selectedTip = getters.selectedTip;
    if (selectedTip === 4) selectedTip = 0;
    const tipAmount = getTipTotal(selectedTip ?? 0, orderSum);

    if (!order.OrderInfo.DeliveryDateTime)
      //1 minute back in time in case of time desync between this computer and BE
      order.OrderInfo.DeliveryDateTime = new Date(Date.now() - 60000);
    order.OrderInfo.DeliveryDateTime =
      order.OrderInfo.DeliveryDateTime.toISOString();
    order.OrderInfo.CheckoutType = CheckoutType.NetsEasy;
    const model = {
      OrderInfo: order.OrderInfo,
      Reservation: order.Reservation,
      RoomId: null,
      Table: order.Table,
      ShoppingCart: currentCart.map((x) => {
        return {
          menuItemId: x.menuItemId,
          NrOfItems: x.nrOfItems,
          TotalPrice: x.totalPrice,
          VariantId: x.variant ? x.variant.id : null,
          CustomerMessage: x.CustomerMessage,
          Choices: x.choices.map((y) => {
            return {
              FoodPropertyCategoryId: y.foodPropertyId,
              FoodPropertyChoiceId: y.id,
            };
          }),
        };
      }),
    };
    model.OrderInfo.TipAmount = tipAmount;
    const res = await api.saveOrder(model, this.state.token);
    commit("ORDER_COMPLETION_STATUS", res);
    return res;
  },
  // async clearSubmittedOrderCookie({ commit }) {
  //   commit("CLEAR_SUBMITTED_ORDER_COOKIE");
  // },
  async getOrderStatus({ commit }, orderGuid) {
    const data = await api.getOrderStatus(orderGuid);
    return data;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getOrderReciept({ commit }, orderId) {
    const data = await api.getOrderReceipt(orderId, this.state.token);
    return data;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getOrderRecieptPdf({ commit }, orderId) {
    try {
      const data = await api.getOrderReceiptPdf(orderId, this.state.token);
      return data;
    } catch (error) {
      return error;
    }
  },
  async clearShoppingCart({ commit, dispatch }) {
    commit("CLEAR_CART");
    commit("CLEAR_DINEIN");
    commit("SET_LAST_MENU_ID", null);
    dispatch("updateErrorStatus");
    return true;
  },
  async clearLocalShoppingCart({ commit }) {
    commit("CLEAR_LOCAL_STORAGE_CART");
    return true;
  },
  async initShoppingCart({ commit, dispatch }) {
    commit("INIT_SHOPPING_CART");
    dispatch("updateErrorStatus");
    return true;
  },
  async initTableObject({ commit }, tableValues) {
    commit("INIT_TABLE_OBJECT", tableValues);
    return true;
  },
  async removeExpiredTableObject({ commit }) {
    commit("REMOVE_EXPIRED_TABLE_OBJECT");
  },
  initDeliveryObject({ commit }, payload) {
    commit("INIT_DELIVERY_OBJECT", payload);
    return true;
  },
  async updateUserProfile({ commit }, newUserData) {
    //const updatedProfile = getters.userProfile;
    const res = await api.updateUserProfile(newUserData, this.state.token);
    if (res.status === 200) return true;
    return false;
  },
  async updateUserPassoword({ commit }, newUserPassword) {
    const res = await api.updateUserPassoword(
      newUserPassword,
      this.state.token
    );
    if (res.status === 200) return true;
    return false;
  },
  async registerUser({ commit }, credentials) {
    const data = await api.registerUser(
      credentials.newUserInfo,
      credentials.guid
    );
    commit("SET_TOKEN", data.token);
    commit("SET_REFRESH_TOKEN", data.refreshToken);
    return data.token;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async registerMinimalUser({ commit }, allRegisterInfo) {
    const registerInfo = { Email: allRegisterInfo.Email };
    const res = await api.registerMinimalUser(
      registerInfo,
      allRegisterInfo.OrderGUID
    );
    return res;
  },
  async refreshTokens({ commit }) {
    const credentials = {
      Token: this.state.token,
      RefreshToken: this.state.refreshToken,
    };
    const tokenData = await api.refreshTokens(credentials);
    commit("SET_TOKEN", tokenData.token);
    commit("SET_REFRESH_TOKEN", tokenData.refreshToken);
  },
  async logInUser({ commit }, credentials) {
    const data = await api.logInUser(credentials);
    commit("SET_TOKEN", data.token);
    commit("SET_REFRESH_TOKEN", data.refreshToken);
    return data.token;
  },
  async logOutUser({ commit, dispatch }) {
    if (this.state.loggedInUser != null) {
      const userCredentials = {
        Email: this.state.loggedInUser.email,
      };
      await api.revokeToken(userCredentials);
      commit("LOGGED_IN_USER", null);
      commit("SET_TOKEN", null);
      commit("SET_REFRESH_TOKEN", null);
      dispatch("setLastSelectedEatery", null);
      return true;
    }
    if (router.currentRoute.path != "/") {
      router.push({ path: "/" });
    }
  },
  async getUserInfo({ commit }) {
    const user = await api.getUserInfo(this.state.token);
    commit("LOGGED_IN_USER", user);
    return user;
  },
  async getUserAddress({ commit }) {
    const address = await api.getUserAddress(this.state.token);
    const user = Object.assign({}, this.state.loggedInUser);

    user.address = address;
    commit("LOGGED_IN_USER", user);
    return user;
  },
  // eslint-disable-next-line
  async sendtReciptToEmail({ commit }, sendReciptInfo) {
    const res = await api.sendtReciptToEmail(
      sendReciptInfo.Email,
      sendReciptInfo.OrderGUID
    );
    return res;
  },
  async verifyAndGetPaymentId({ commit, getters }, orderInfo) {
    const currentCart = getters.cart;

    const model = {
      OrderInfo: orderInfo,
      ShoppingCart: currentCart.map((x) => {
        return {
          MenuItemId: x.menuItemId,
          NrOfItems: x.nrOfItems,
          TotalPrice: x.totalPrice,
          Choices: x.choices.map((y) => {
            return {
              FoodPropertyCategoryId: y.foodPropId,
              FoodPropertyChoiceId: y.foodOption.id,
            };
          }),
        };
      }),
    };
    const result = await api.verifyAndGetPaymentId(model);
    commit("SET_PAYMENT_ID", result);
    return result;
  },
  //getDineInById
  getDineInById({ commit }, id) {
    if (id) {
      return new Promise((resolve, reject) => {
        api.getDineInById(id).then(
          (dineInOrderInfo) => {
            commit("SET_DINEIN", dineInOrderInfo);
            resolve(dineInOrderInfo);
          },
          (error) => {
            reject(error);
          }
        );
      });
    }
  },

  clearDineIn({ commit }) {
    commit("CLEAR_DINEIN");
  },
  clearTableObject({ commit }) {
    commit("CLEAR_TABLE_OBJECT");
  },
  setStartUrl({ commit }, payload) {
    commit("SET_START_URL", payload);
  },
  async sendResetPasswordToken({ commit }, email) {
    const response = await api.sendResetPasswordToken(email);
    return response;
  },
  async resetPassword({ commit }, user) {
    const response = await api.resetPassword(user);
    return response;
  },
  setDeliveryOption({ commit, dispatch }, deliveryOption) {
    commit("SET_DELIVERY_OPTION", deliveryOption);
    dispatch("updateErrorStatus");
  },
  setPaymentId({ commit }, paymentId) {
    commit("SET_PAYMENT_ID", paymentId);
  },
  setCheckoutKey({ commit }, checkoutKey) {
    commit("SET_CHECKOUT_KEY", checkoutKey);
  },
  async sendTableReservation({ commit }, tableReservationObject) {
    const response = await api.sendTableReservation(tableReservationObject);
    commit("SET_TABLE_RESERVATION", tableReservationObject);
    return response;
  },
  clearDeliveryOption({ commit }) {
    commit("CLEAR_DELIVERY_OPTION");
  },
  async addInfectionTracking({ commit }, visitors) {
    const response = await api.addInfectionTracking(visitors, this.state.token);
    return response;
  },
  async updateTokens({ commit }, data) {
    commit("SET_TOKEN", data.token);
    commit("SET_REFRESH_TOKEN", data.refreshToken);
  },
  setToken({ commit }, token) {
    commit("SET_TOKEN", token);
  },
  setResetToken({ commit }, token) {
    commit("SET_RESET_TOKEN", token);
  },
  setLastSelectedEatery({ commit }, eateryId) {
    commit("SET_LAST_SELECTED_EATERY", eateryId);
  },
  lockEatery({ commit }, eateryId) {
    commit("LOCK_TO_EATERY", eateryId);
  },
};
