import Moment from "moment";
import { EatingMode } from "@/types/enums/eatingMode";
import UserInformationObject from "@/types/checkout/userInformation";
import NetsCheckout from "@/components/NetsCheckout.vue";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import ErrorHelper from "@/helpers/errorHelpers";
import props from "./PaymentDialog.props";
import { CheckoutType } from "@/types/enums/checkoutType";

const codebehind = {
  mixins: [props],
  components: { NetsCheckout, ErrorComponent },
  data() {
    return {
      dialog: false,
      error: false,
      errorMessage: "",
      paymentId: null as string,
      checkoutKey: null as string,
      lastOrderObject: null as any,
      showDebug: false as boolean,
    };
  },
  computed: {
    currentEatingMode() {
      if (this.$store.getters.selectedEatingMode)
        return this.$store.getters.selectedEatingMode;
      return EatingMode.TakeAway;
    },
    currentEatery() {
      return this.$store.getters.eateryInfo;
    },
    ShoppingCart() {
      return this.$store.getters.cart;
    },
    TableObject() {
      return this.$store.getters.tableObject;
    },
    ErrorOrderFields() {
      return `
      Adresse: ${this.lastOrderObject.OrderInfo.Address.StreetAddress} - ${this.lastOrderObject.OrderInfo.Address.PostalCode} ${this.lastOrderObject.OrderInfo.Address.PostalArea}
      CheckoutType: ${this.lastOrderObject.OrderInfo.CheckoutType}
      DeliveryDateTime: ${this.lastOrderObject.OrderInfo.DeliveryDateTime}
      EateryId: ${this.lastOrderObject.OrderInfo.EateryId}
      EatingMode: ${this.lastOrderObject.OrderInfo.EatingMode}
      PaymentStatus: ${this.lastOrderObject.OrderInfo.PaymentStatus}
      TipAmount: ${this.lastOrderObject.OrderInfo.TipAmount}
      `
    }
  },
  methods: {
    closeDialog() {
      this.paymentId = null as string;
      this.errorMessage = "";
      this.dialog = false;
    },
    getTableNr() {
      return this.$store.getters.tableObject.TableId;
    },
    getTableObject(tableObject: any) {
      const tempTableId = tableObject.TableId
        ? this.getTableNr()
            .replace(/^"(.*)"$/, "$1")
            .toString()
        : this.tableId;
      return {
        TableId: tempTableId,
        completed: true,
      };
    },
    getOrderObject() {
      const baseUrl = window.location.origin;
      const fromUrl = window.location.origin + this.$route.path;
      let orderDateTime: Date;
      let tableObj;

      if (this.selectedDate) {
        orderDateTime = (
          Moment(this.selectedDate + " " + this.selectedTime, "YYYY-MM-DD HH:mm", true) as any
        )._d;

        const now = Moment();
        const selectedDateTime = Moment(
          this.selectedDate + " " + this.selectedTime,
          "YYYY-MM-DD HH:mm",
          true
        );
        if (selectedDateTime.isBefore(now)) {
          const hour = now.hour().toString().padStart(2, "0");
          const minutes = now.minutes().toString().padStart(2, "0");
          const newTime = `${hour}:${minutes}`;
          orderDateTime = (Moment(this.selectedDate + " " + newTime, "YYYY-MM-DD HH:mm", true) as any)._d;
        }
      } else {
        orderDateTime = (Moment(undefined, "YYYY-MM-DD HH:mm", true)as any)._d;
      }

      if (this.TableObject) {
        tableObj = this.getTableObject(this.TableObject);
      }
      if (this.TableObject && this.TableObject.TableId) {
        return {
          OrderInfo: {
            CompanyFriendlyName: this.currentEatery.url,
            EateryId: parseInt(this.currentEatery.eateryId),
            Ordernote: this.$store.getters.messageToKitchen,
            BaseUrl: baseUrl,
            FromUrl: fromUrl,
            PaymentStatus: 1,
          },
          Table: tableObj,
        };
      }
      const userInfo = this.userInformationObject as UserInformationObject;
      const obj = {
        OrderInfo: {
          Fullname: userInfo.fullName,
          Address: {
            StreetAddress: userInfo.address,
            PostalCode: userInfo.postalcode,
            PostalArea: userInfo.postalcity,
          },
          CheckoutType: CheckoutType.NetsEasy,
          Phone: userInfo.phone?.replace(/[^0-9+]/g, ""),
          Email: userInfo.email,
          Ordernote: this.$store.getters.messageToKitchen,
          Deliverynote: this.deliverynote,
          EatingMode: this.currentEatingMode,
          DeliveryDateTime: orderDateTime,
          CompanyFriendlyName: this.currentEatery.url,
          EateryId: parseInt(this.currentEatery.eateryId),
          BaseUrl: baseUrl,
          FromUrl: fromUrl,
          PaymentStatus: 1,
        },
        RoomId: null as any,
        Table: tableObj,
      };
      if (this.TableObject && !this.TableObject.TableId)
        obj.OrderInfo.EatingMode = EatingMode.DineIn;
      this.lastOrderObject = obj;
      return obj;
    },

    async pay() {
      this.error = false;
      const submitNewOrderObject = this.getOrderObject(); // TODO: Check how to deal with this ? Props?
      try {
        const res = await this.$store.dispatch(
          "saveOrder",
          submitNewOrderObject
        );
        if (!res.success) {
          throw { response: res };
        }
        this.errorMessage = "";
        this.error = false;

        if (!res.paymentId) {
          this.$router.push(
            "/" + this.$store.getters.eateryName + "/orderfailed"
          );
        } else {
          this.startNetsCheckout(res.paymentId, res.checkoutKey);
        }
      } catch (e) {
        this.error = true;
        if(e.response) {
          this.errorMessage = await ErrorHelper.getOrderErrorCodeAsync(
            e.response
          );
        } else {
          this.errorMessage = e.message;
        }
        this.dialog = true;
      }
    },
    startNetsCheckout(paymentId: string, checkoutKey: string) {
      this.paymentId = paymentId;
      this.checkoutKey = checkoutKey;
      this.$store.dispatch("setPaymentId", paymentId);
      this.$store.dispatch("setCheckoutKey", checkoutKey);
      this.dibsIsInit = true;
      this.dialog = true;
    },
    async paymentComplete(response: any) {
      //const submittedOrderObject = await this.$store.dispatch("getSubmittedOrderCookie");
      let paymentId = null;
      let checkoutKey = this.checkoutKey;
      if (response.paymentId) {
        paymentId = response.paymentId;
      }
      if (response.checkoutKey) {
        checkoutKey = response.checkoutKey;
      }
      if (paymentId) {
        this.$router.push(
          `/${this.$store.getters.eateryName}/success/?paymentid=${paymentId}&checkoutKey=${checkoutKey}`
        );
      }
    },
  },
};
export default codebehind;
